<template>
    <div id="Layer-warp">
        <el-container class="Layer-warp">
            <el-aside id="nav-warp" width="200px">
                <LayerNav />
            </el-aside>
            <el-container>
                <el-header id="header-warp"><LayerHeader /></el-header>
                <el-main><LayerMain /></el-main>
            </el-container>
        </el-container>
    </div>
</template>
<script>
import LayerNav from "./components/Navigation";
import LayerHeader from "./components/Header";
import LayerMain from "./components/Main";

export default {
    components:{
        LayerNav,
        LayerHeader,
        LayerMain
    },
    name:'Layer',


}
</script>

<style scoped>
#Layer-warp{
    height: 100vh;
    
}
.Layer-warp{
    height: 100vh;
    background: #F9F9F9;
}
#header-warp{
    box-shadow: 0px 2px 20px 0px rgba(51, 51, 51, 0.19);
    background: #ffffff;
}
#nav-warp{
    background: #F0F2F3;
}
</style>