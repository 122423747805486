<template>
    <div>
        <div class="header-all" >
            <div>欢迎您登陆手表进销存管理系统</div>
            <el-image class="image-out"  @click="outClick" :src="require('../../../assets/image/out.png')" />
        </div>

        <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="20%"
            :before-close="handleClose">
            <span>是否退出登录</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="affirmOut">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>
<script>
export default {
    name:'Header',
    data() {
      return {
        dialogVisible: false
      };
    },
    methods:{
        //退出登录
        outClick(){
            this.dialogVisible = true
            
        },
        // 确认退出
        affirmOut(){
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('user_info')
            this.$router.replace('/')
            this.dialogVisible = false
        },
        // 点击遮罩
        handleClose(done) {}
    }
}
</script>
<style scoped>
.header-all{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    color: #3F88FF;
}
.image-out{
    width: 80px;
    height: 80px;
}
</style>